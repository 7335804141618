import React from 'react'
import { Result, Button, Spin} from 'antd';
import { Link, useLocation, useHistory } from "react-router-dom";
import { USER_MAILBOXES } from '../Routes/Constants/RouteUrls';
import { planDetailsAction } from '../Store/Actions/planDetailsActions';
import { useSelector, useDispatch } from 'react-redux';

const PageNotFoundScreen = () => {
    const userId = localStorage.getItem("userId")
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { planData, loading, error:planError } = useSelector((state) => state.planDetails);
    const noPlanFound = !planData || !planData.planData;
    const planNotFound = (location.pathname === "/plan-not-found");
    const extra = planNotFound
                    ?  <Button type="primary" onClick={()=>getPlan()}>Try Again</Button>
                    :  <Link to={userId ? USER_MAILBOXES : ''}><Button type="primary">Back Home</Button></Link>
    const title = planNotFound 
                    ? ""
                    : "404"
    const subTitle = planNotFound 
                    ? "Looks like it takes longer to load your app. Our engineers have been notified and they're looking into the issue. Please try again in a few minutes."
                    : "Sorry, the page you visited does not exist."
    const getPlan = async () => {
        await dispatch(planDetailsAction(userId));
    }
    if(!noPlanFound){
        history.push(USER_MAILBOXES);
    } else {
        planError?.errorMessage && history.push("/pricing");
    }
    return (
        <div>{
                loading
                ? <Spin className="p-56" size="large" spinning={true}></Spin>
                : <Result
                        status={planNotFound?"500":"404"}
                        title={title}
                        subTitle={subTitle}
                        extra={extra}
                />
            }
        </div>
    )
}

export default PageNotFoundScreen