import './App.css';
import './assets/main.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AppSumoScreen from './Screens/AppSumoScreen';
import LoginScreen from './Screens/LoginScreen';
import LogoutScreen from './Screens/LogoutScreen';
import PageNotFoundScreen from './Screens/PageNotFoundScreen';
import PricingScreen from './Screens/PricingScreen';
import ProtectedUserRoute from './Routes/ProtectedUserRoute';
import PublicRoute from './Routes/PublicRoute';
import React from 'react';
import SignupScreen from './Screens/SignupScreen';
import SinglePageRoute from './Routes/SinglePageRoute';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <PublicRoute exact path="/" component={LoginScreen} />
          <PublicRoute path="/signup" component={SignupScreen} />
          <PublicRoute path="/logout" component={LogoutScreen} />
          <SinglePageRoute path="/pricing" component={PricingScreen} />
          <PublicRoute path="/appsumo/:appsumo_code?" component={AppSumoScreen} />
          <PublicRoute path="/promocode/:appsumo_code?" component={AppSumoScreen} />
          <Route path="/user" render={ProtectedUserRoute} />
          <Route path="" component={PageNotFoundScreen} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
