import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import {
  archivedInsightsReducer,
  domainInsightsCountReducer,
  mailboxInsightsReducer,
  userInsightsReducer,
} from './Reducers/insightsReducers';
import {
  closeApiReducer,
  getCadencesForUserReducer,
  getCloseInfoApiReducer,
  getCloseMailboxesApiReducer,
  getCustomFieldsInfoReducer,
  getIntegrationDetailsApiReducer,
  getOutreachInfoApiReducer,
  getOutreachMailboxesApiReducer,
  getSalesloftInfoApiReducer,
  getSalesloftMailboxesApiReducer,
  outreachApiReducer,
  reauthOutreachApiReducer,
  salesloftApiReducer,
  updateCustomFieldsReducer,
  updateMailboxStatusApiReducer,
  userMailboxRecommendReducer,
} from './Reducers/integrationReducers';
import {
  companyDetailsReducer,
  companyDetailsSaveReducer,
} from './Reducers/companyDetailsReducers';
import {
  contentTestsCreateUpdateReducer,
  contentTestsDeleteReducer,
  contentTestsDraftReducer,
  contentTestsFinishReducer,
  contentTestsReducer,
} from './Reducers/contentTestsReducers';
import {
  getMailboxThrottlingDataReducer,
  getThrottlingMailboxesReducer,
} from './Reducers/throttlingReducers';
import { loadState, saveState } from './localStorage';
import {
  mailboxConnectReducer,
  mailboxDetailsUpdateReducer,
  mailboxDomainListsReducer,
  mailboxDomainStatisticsReducer,
  mailboxIdStatisticsReducer,
  mailboxListsLeaderboardReducer,
  mailboxValidateReducer,
} from './Reducers/mailboxReducers';
import {
  outgoingMailboxDailyFutureLimitReducer,
  outgoingMailboxDailyFutureLimitSaveReducer,
  outgoingMailboxExpectedEmailsReducer,
} from './Reducers/outgoingMailboxReducers';
import { planAllDetailsReducer, planDetailsReducer } from './Reducers/planDetailsReducers';
import {
  safetyNetAllMailboxesChartDataReducer,
  safetyNetAllMailboxesTimelineDataReducer,
  safetyNetEverywhereCreditsDataReducer,
  safetyNetEverywhereFileDataReducer,
  safetyNetEverywhereJobsReducer,
  safetyNetEverywhereStatsAndDataReducer,
  safetyNetEverywhereValidationsByStatusReducer,
  safetyNetEverywhereValidationsForJobReducer,
  safetyNetMailboxChartDataReducer,
  safetyNetMailboxTimelineDataReducer,
} from './Reducers/safetyNetReducers';
import { userRegisterReducer, userSigninReducer, userUpdateReducer } from './Reducers/userReducers';

import { throttle } from 'lodash';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,

  mailboxIdStatistics: mailboxIdStatisticsReducer,
  mailboxDetailsUpdate: mailboxDetailsUpdateReducer,
  mailboxConnectInfo: mailboxConnectReducer,
  mailboxDomainListsInfo: mailboxDomainListsReducer,
  mailboxListsLeaderboardInfo: mailboxListsLeaderboardReducer,
  mailboxDomainStatisticsInfo: mailboxDomainStatisticsReducer,
  mailboxValidateInfo: mailboxValidateReducer,

  outgoingMailboxDailyFutureLimit: outgoingMailboxDailyFutureLimitReducer,
  outgoingMailboxDailyFutureLimitSave: outgoingMailboxDailyFutureLimitSaveReducer,
  outgoingMailboxExpectedEmails: outgoingMailboxExpectedEmailsReducer,

  companyDetails: companyDetailsReducer,
  companyDetailsSave: companyDetailsSaveReducer,

  planDetails: planDetailsReducer,
  planAllDetails: planAllDetailsReducer,

  userInsightsDetails: userInsightsReducer,
  mailboxInsightsDetails: mailboxInsightsReducer,
  archivedInsightsDetails: archivedInsightsReducer,
  domainInsightsCountDetails: domainInsightsCountReducer,

  contentTestsInfo: contentTestsReducer,
  contentTestsCreateUpdateInfo: contentTestsCreateUpdateReducer,
  contentTestsDraftInfo: contentTestsDraftReducer,
  contentTestsDeleteInfo: contentTestsDeleteReducer,
  contentTestsFinishInfo: contentTestsFinishReducer,

  closeApiInfo: getCloseInfoApiReducer,
  closeMailboxesInfo: getCloseMailboxesApiReducer,
  outreachApiInfo: outreachApiReducer,
  outreachApiInfo: getOutreachInfoApiReducer,
  outreachMailboxesInfo: getOutreachMailboxesApiReducer,
  salesloftApiInfo: salesloftApiReducer,
  salesloftApiInfo: getSalesloftInfoApiReducer,
  salesloftMailboxesInfo: getSalesloftMailboxesApiReducer,
  userMailboxRecommendInfo: userMailboxRecommendReducer,
  updateMailboxStatus: updateMailboxStatusApiReducer,
  cadencesForUser: getCadencesForUserReducer,
  integrationDetails: getIntegrationDetailsApiReducer,
  customFieldsData: getCustomFieldsInfoReducer,

  safetyNetAllMailboxesChartData: safetyNetAllMailboxesChartDataReducer,
  safetyNetAllMailboxesTimelineData: safetyNetAllMailboxesTimelineDataReducer,
  safetyNetMailboxChartData: safetyNetMailboxChartDataReducer,
  safetyNetMailboxTimelineData: safetyNetMailboxTimelineDataReducer,
  safetyNetEverywhereJobsData: safetyNetEverywhereJobsReducer,
  safetyNetEverywhereFileData: safetyNetEverywhereFileDataReducer,
  safetyNetEverywhereStatsAndData: safetyNetEverywhereStatsAndDataReducer,
  safetyNetEverywhereValidationsByStatus: safetyNetEverywhereValidationsByStatusReducer,
  safetyNetEverywhereValidationsForJob: safetyNetEverywhereValidationsForJobReducer,
  safetyNetEverywhereCreditsData: safetyNetEverywhereCreditsDataReducer,

  throttlingMailboxes: getThrottlingMailboxesReducer,
  mailboxThrottlingData: getMailboxThrottlingDataReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const store = createStore(reducer, persistedState, composeEnhancer(applyMiddleware(thunk)));

store.subscribe(
  throttle(() => {
    saveState({
      planDetails: store.getState().planDetails,
    });
  }, 1000),
);

export default store;
