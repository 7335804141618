import { Button } from 'antd';
import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  const component = (props) => (
    <div className="h-screen">
      <div className="w-full h-full flex">
        <div className="px-12 text-left w-4/12 flex-col h-screen">
          <div className="flex-row justify-center md:justify-start pt-6 h-full">
            <div className="pb-10">
              <a href="https://www.allegrow.co/" rel="noopener noreferrer" target="_blank">
                <img
                  width={180}
                  height={40}
                  alt="Allegrow logo and homepage link"
                  src="/images/logo_allegrow.svg"
                />
              </a>
            </div>
            <div className="w-46">
              <h1 className="text-3xl">Make sure your emails reach the inbox.</h1>
            </div>
            <div className="screen-middle-public flex-row">
              <Component {...props} />
            </div>
          </div>
        </div>
        <div className="w-8/12 signup-img p-20 h-screen">
          <section className="mt-6 absolute right-0">
            <div className="w-46 pr-24 -mt-16 text-right">
              <h1 className="text-3xl">Find more resources!</h1>
              <h1 className="text-3xl">Explore our Knowledge Base</h1>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.allegrow.co/knowledge-base">
                <Button
                  className="float-right mt-4 w-32 text-gray-500 rounded-md"
                  size="large"
                  htmlType="submit">
                  Learn More
                </Button>
              </a>
            </div>
          </section>
          <img className="w-full h-full" alt="Signup" src="/images/onboarding_background.svg" />
        </div>
      </div>
    </div>
  );
  return <Route {...rest} component={component} />;
};

export default PublicRoute;
