const blockedDomains = [
  'gmail.',
  'outlook.',
  'yahoo.',
  'zoho.',
  'mail.',
  'protonmail.',
  'gmx.',
  'aol.',
  'icloud.',
  'yandex.',
];

const generateBlockedDomainsPattern = () => {
  const escapedDomains = blockedDomains.map((domain) => domain.replace('.', '\\.'));
  const pattern = `^(?!.*(${escapedDomains.join('|')}))`;
  return new RegExp(pattern);
};

export default generateBlockedDomainsPattern;
